import React, { useState, useRef, useEffect } from "react";
import NavBar from "../components/navbar";
import "../components/homevideo.css";
//import vote from "../images/bestweb-24-vote4us.webp"; //  BestWeb - logo
import TopWeb from "../images/TopWeb-May-2024.jpg"; //  TopWeb - logo
import Footer3CS from "../components/footerbar";
import Arrow from "../template/ScrollToTopButton";
import {
  Parallax,
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";
import { Helmet } from "react-helmet";
import "./about.css";

const AboutPage = () => {
  //  BestWeb - start
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth <= 600);

      const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  //  BestWeb - end

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const mainRef = useRef(null);

  const handleClick = () => {
    mainRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ParallaxProvider>
      <div style={{ backgroundColor: "#1f1f1f" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            About Us | Lalan Group Sri Lanka | Largest Conglomerates Sri Lanka
          </title>
          <meta
            name="title"
            content="About Us | Lalan Group Sri Lanka | Largest Conglomerates Sri Lank"
          />
          <meta
            name="description"
            content="Founded in 1940, the Lalan Group is among the largest diversified conglomerates in Sri Lanka focused on a variety of industries like plantations, manufacturing, logistics, printing and packaging, engineering services and hospitality."
          />
          <meta
            name="keywords"
            content="About Us, Largest Conglomerates Sri Lanka"
          />
          <link rel="canonical" href="https://www.lalangroup.com/about/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="About US - Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
          <meta
            property="og:url"
            content="https://www.lalangroup.com/contact-us/"
          />
          <meta
            property="og:site_name"
            content="Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
        </Helmet>

        <style>
          {`
            /* Customize scrollbar */
            ::-webkit-scrollbar {
              width: 4px;
              background-color: #1f1f1f;
            }

            ::-webkit-scrollbar-thumb {
              background-color: #5689c7;
              background-clip: content-box;
            }

            ::-webkit-scrollbar-thumb:hover {
              background-color: #5689c7;
            }

            ::-webkit-scrollbar-track {
              background-color: #1f1f1f;
            }
          `}
        </style>

        <NavBar />
        <div style={{ position: "relative" }}>
          <ParallaxBanner style={{ aspectRatio: "2 / 1.124", height: "100vh" }}>
            <ParallaxBannerLayer
              image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/aboutpic.jpg"
              speed={10}
            />
          </ParallaxBanner>
          <div className="top-font-block">
            <Parallax speed={10}>
              <img
                className="video-font-png"
                src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/about-us-lable.png"
                alt="font"
              />
            </Parallax>
          </div>
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="mouse-color-about">
              <img
                onClick={handleClick}
                src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/uploads/images/gif-mouse.gif"
                alt="button"
                className="mouse-button"
                style={{ height: "90px", zIndex: "10" }}
              />
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "#1f1f1f" }}>
          <div ref={mainRef}>
            {/* Main content */}
            <div className="about-us-style">
              <br />
              <center style={{ marginTop: "100px" }}>
                <Parallax speed={1}>
                  <h1 className="bigHugeTitle">
                    About <br />
                    <span
                      style={{
                        color: "#46708a",
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      Lalan Group
                    </span>
                  </h1>
                </Parallax>
              </center>

              <Parallax speed={9}>
                <div className="about-gap">
                  <div
                    style={{
                      marginLeft: "60px",
                      marginRight: "60px",
                      marginTop: "-40px",
                    }}
                  >
                    <p className="para">
                      Established in 1940, the Lalan Group is among the largest
                      diversified conglomerates in Sri Lanka, with over 46
                      subsidiaries engaged in plantations, manufacturing,
                      logistics, printing and packaging, engineering services
                      and hospitality. With roots in the plantation sector and
                      over 17,500 acres of plantation property, our unique
                      vertical integration enables efficient control of the
                      entire supply chain in the plantation and production
                      sectors. Lalan's rubber manufacturing operations produce
                      the finest quality latex and rubber value-added products.
                      We work closely with rubber smallholders and the
                      Government to develop the rubber industry.
                      <br />
                      <br />
                      Our commitment to quality has earned Lalan the highest
                      standards of quality certification in our processes,
                      including the Forest Stewardship Certification.
                      <br />
                      <br />
                      10,000 passionate employees form the backbone of Lalan's
                      success and we pride ourselves on being a corporate
                      citizen that is committed to our core values, while
                      providing our customers with flexibility and adaptability
                      for the best industrial and service solutions.
                    </p>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {/* BestWeb - start */}
                    {/* <div>
                      <a
                        href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
                        target="_blank"
                      >
                        <img
                          style={{
                            marginLeft: isMobile ? "40px" : "60px",
                            marginBottom: isMobile ? "0px" : "70px",
                          }}
                          className="logopic"
                          src={vote}
                          alt="logo"
                        />
                      </a>
                    </div> */}
                    {/* BestWeb - end */}

                    {/* TopWeb - start */}
                    <div>
                      <a
                        href="https://topweb.lk/may2024/lalangroup"
                        target="_blank"
                      >
                        <img
                          style={{
                            marginBottom: isMobile ? "0px" : "70px",
                            marginLeft: isMobile ? "50px" : "100px",
                          }}
                          className="logopic-2"
                          src={TopWeb}
                          alt="logo"
                        />
                      </a>
                    </div>
                    {/* TopWeb - end */}
                  </div>
                </div>
              </Parallax>
            </div>
          </div>

          <div style={{ marginTop: "100px" }}>
            <Footer3CS />
          </div>
          <Arrow />
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default AboutPage;
